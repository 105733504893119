// src/components/Footer.js
import React from 'react';
import { Nav, Container, Row, Col } from 'react-bootstrap';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import Flag from 'react-world-flags';
import LanguageSwitcher from './LanguageSwitcher';


const Footer = () => {
    const { lang } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation(); // Get the current path




    return (
        <footer className="bg-light py-3">

            <Container className="p-4">
                <Row>
                    <Col md={12} className="p-4">
                        <h5 className="text-center font-weight-bold">{t('global.h5_dwonload')}</h5>
                        <div className="d-flex justify-content-center">
                            <a href="https://play.google.com/store/apps/details?id=com.app.petshome" target="_blank" rel="noopener noreferrer" className="mr-3" title="Download Pets Home App from Google Play Store">
                                <img style={{ width: '180px', height: 'auto' }} src="/google-play-badge.svg" alt="Download Pets Home App on Google Play" />
                            </a>
                            <a href="https://apps.apple.com/app/apple-store/id1549828591?pt=581834&ct=desktop&mt=8" target="_blank" rel="noopener noreferrer" title="Download Pets Home App from Apple App Store">
                                <img style={{ width: '180px', height: 'auto' }} src="/app-store-badge.svg" alt="Download Pets Home App on App Store" />
                            </a>
                        </div>
                        <div className="d-flex justify-content-center">
                            {/* <small> {t('rated')} </small> */}
                            <div>
                                <FontAwesomeIcon icon={faStar} color="gold" />
                                <FontAwesomeIcon icon={faStar} color="gold" />
                                <FontAwesomeIcon icon={faStar} color="gold" />
                                <FontAwesomeIcon icon={faStar} color="gold" />
                                <FontAwesomeIcon icon={faStar} color="gold" />
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-4">
                            <img style={{ width: '130px', height: 'auto' }} src="/android-adobe-express-qr-code.png" alt="Android QR Code" className="mr-3" />
                            <img style={{ width: '130px', height: 'auto' }} src="/ios-express-qr-code.png" alt="iOS QR Code" />
                        </div>
                        <p className="text-center mt-3 d-none d-md-block">Scan the QR codes below for quick access:</p>
                    </Col>
                </Row>
            </Container>
            <div className="container">
                <Nav className="justify-content-center">
                    <Nav.Item>
                        <Nav.Link as={Link} to={`/${lang || 'en'}/terms-of-use`}>
                            Terms
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to={`/${lang || 'en'}/privacy-policy`}>
                            Privacy Policy
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>


                        <Nav.Link as={Link} to={`/${lang || 'en'}/partnership-opportunities`}> {t('global.LetsPartner')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>


                        <Nav.Link as={Link} to={`/${lang || 'en'}/affiliate`}> {t('global.Affiliate')}  </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to={`/${lang || 'en'}/about-us`}> {t('aboutUs.title')} </Nav.Link>
                    </Nav.Item>


                    <Nav.Item>
                        <Nav.Link href="https://outfit.pet/" >
                            {t('Pet Clothing')}
                        </Nav.Link>
                    </Nav.Item>
                    <LanguageSwitcher />
                </Nav>
            </div>


        </footer>
    );
};

export default Footer;
